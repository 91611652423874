// InvestED primary color palette
$ed-red: #ED1820;
$ed-warm-gray: #6A5745;
$ed-light-blue: #9EB9DF;
$ed-dark-blue: #7390B8;
$ed-pale-yellow: #FFE488;
$ed-bright-green: #D5E04D;
$ed-green: #587C23;
$ed-light-gray: #CFC8BE;
$ed-gold: #FFC525;

// InvestED secondary color palette
$ed-light-warm-gray: #F2ECE2;
$ed-secondary-warm-gray: #8D755D;
$ed-off-white: #FCFCFC;
$ed-muted-warm-gray: #9D8167;

// warm grayscale
$ed-off-white: #FCFCFC;
$ed-warm-gray-100: #E9BF98;
$ed-warm-gray-200: #CFC8BE;
$ed-warm-gray-300: #B49476;
$ed-warm-gray-400: $ed-muted-warm-gray;
$ed-warm-gray-500: $ed-secondary-warm-gray;
$ed-warm-gray-600: $ed-warm-gray;
$ed-warm-gray-700: #58493A;
$ed-warm-gray-800: #41352A;
$ed-warm-gray-900: #29221B;

// Font Variables
$inter: 'Inter', sans-serif;
$font-family-sans-serif: $inter;