@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'src/styles/variables';
@import 'src/styles/overrides';
@import 'node_modules/bootstrap/scss/bootstrap';

html, body {
  height: 100%;
}

/* basic styles */
.text-decoration-none {
  text-decoration: none;
  &.hover {
    text-decoration: none;
  }
}

/* InvestED brand bg colors */
.bg-ed-light-blue {
  background-color: $ed-light-blue;
}
.bg-ed-dark-blue {
 background-color: $ed-dark-blue;
}
.bg-faded-pale-yellow {
  background-color: rgba($ed-pale-yellow, 0.35)
}
.text-ed-dark-blue {
  color: $ed-dark-blue;
}
.text-ed-green {
  color: $ed-green;
}
.text-ed-warm-gray-200 {
  color: $ed-warm-gray-200;
}
.text-red {
  color: red;
}
.hover-ed-dark-blue {
  &:hover {
    color: $ed-dark-blue;
  }
}
.border-ed-dark-blue {
  border-color: $ed-dark-blue !important;
}

.container-right {
  display: flex;
  padding-left: 0;
}
.main-container {
  position: absolute;
  left: 60px;
  width: calc(100% - 60px);
  max-width: 1200px;
}
@media print {
  .main-container {
    position: absolute;
    left: 0px;
    width: 100%;
    max-width: 100%;
  }
}
.page-title {
  padding-bottom: 0.75rem;
}
.lander {
  padding-left: 1rem;
  padding-right: 1rem;
}
.short-field {
  max-width: 300px;
}
.ws-nowrap {
  white-space: nowrap;
}

/* Spinner */
.main-spinner {
  position: absolute;
  left: 50%;
  top:50%;
}

/* Header actions */
.header-actions {
  .h5, .h6 {
    font-size: xx-small;
    display: block;
    text-align: right;
    margin: 0 42px 0 0;
    padding: 0 0.25rem 0 0;
    white-space: nowrap;
  }
  h5.balance {
    font-weight: bold;
    font-size: medium;
    padding-bottom: 0.25rem;
  }
  .progress {
    height: 0.3rem;
    border-radius: $border-radius;
    background-color: $ed-warm-gray-200;
    margin: 0 46px 0 0;
    .progress-bar {
      height: inherit;
      border-radius: $border-radius;
      background-color: $ed-light-blue;
    }
  }
  .btn.btn-primary {
    margin: 4px 0;
  }
}

/* Header */
.sticky-top {
  position: relative;
  top: 0;
  z-index: 120;
}
.organization-title {
  font-weight: 700;
  line-height: 1.3;
}
.user-title {
  font-size: 0.9rem;
  font-weight: 400;
  .nav-item.dropdown {
    .dropdown-toggle.nav-link {
      padding: 0;
      color: $ed-warm-gray;
    }
    .dropdown-item {
      color: $ed-warm-gray
    }
    .dropdown-item:link,
    .dropdown-item:active,
    .dropdown-item:hover,
    .dropdown-item:visited
    {
      background-color: transparent;
    }
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 60px;
  transition: width 200ms;
  overflow: hidden;
  &.expanded {
    width: 220px;
    transition: width 200ms;
  }
  .logout {
    display: flex;
    align-items: center;
    position: absolute;
    text-decoration: none;
    text-align: left;
    bottom: 0;
    height: 60px;
    margin: 0;
    padding: 0;
    svg {
      width: 60px;
    }
    &:hover {
      background-color: $ed-dark-blue;
      color: $ed-off-white;
    }
  }
}
.sidebar-nav {
  flex-direction: column;
  position: relative;
  top: -1rem;
  z-index: 125;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 60px;
  width: 60px;
  transition: width 200ms;
  &.expanded {
    width: 220px;
    transition: width 200ms;
  }
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: $ed-off-white;
    line-height: 45px;
    margin: 0 auto;
    min-width: 60px;
    padding: 0;
    &.active {
      background-color: $ed-dark-blue;
    }
    &:hover {
      background-color: $ed-dark-blue;
    }
    svg {
      width: 60px;
    }
  }
}
.sidebar-text {
  width: 160px;
}

/* Sign in */
.inner-body {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.form-signin {
  width: 100%;
  max-width: 430px;
  padding: 15px;
  margin: auto;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.admin-link {
  padding-bottom: 0.75rem;
}
.admin-link a {
  display: inline-block;
}
.admin-link a, .admin-link a:link, .admin-link a:visited, .admin-link a:hover, .admin-link a:active {
  text-decoration: none;
}
.admin-link a .link-title {
  text-decoration: underline;
}
.form-control {
  color: $ed-warm-gray;
  &::placeholder {
    color: $ed-warm-gray;
  }
  &.no-left-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.left-label {
  width: 120px;
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
}

/* Students search dropdown */
.name-column {
  width: 40%;
  min-width: 150px;
  overflow: hidden;
  margin-right: 0.5rem;
  
  .text-muted {
    padding-left: 1em;
    font-size: small;
  }
}
.secondary-column {
  width: 20%;
  min-width: 80px;
}
.rbt-menu {
  z-index: 90;
}

/* Data table */
.data-table-clear-btn {
  position: relative;
  padding: 0.2rem 0.3rem;
  background-color: #fff;
  left: -30px;
  text-decoration: none;
}
.data-table-clear-btn:hover {
  text-decoration: none;
}
.global-data-table [role="columnheader"],
.global-data-table [role="columnheader"]:hover,
.global-data-table [role="columnheader"]:focus {
  color: #fff!important;
}
.table-filter-dropdown .dropdown-menu.show {
  max-height: 50vh;
  overflow: auto;
}
.global-data-table.no-pointer * {
  cursor: default!important;
}
@media print {
  .data-rows-print-border div[role="row"] {
    border-bottom: solid 1px rgba(0,0,0,.12)!important;
  }
}
.custom-action-btn {
  float: right;
  min-width: 201px;

  &.disabled {
    background-color: $ed-muted-warm-gray;
    color: $ed-off-white;
  }
}

.school-balance-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  font-size: 14px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  .ledger-balance-left-box {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .percentage-without-text-box {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      font-size: 1.5rem;
      font-weight: 900;
      .percentage-box {
        border-radius: $border-radius;
        padding: 0.1rem 0.3rem;
        margin-right: 0.5rem;
        color: white;
        background-color: $ed-warm-gray-200;
        @include media-breakpoint-down(md) {
          font-size: 1.4rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 1.2rem;
        }
      }
      .iff-box {
        color: $ed-warm-gray-200;
      }
    }
  }
  .ledger-balance-amount {
    font-size: 2.5rem;
    font-weight: 900;
    color: $ed-green;
    margin-left: 1rem;
    @include media-breakpoint-down(md) {
      font-size: 2.25rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }
}
.data-table-sub-header {
  display: flex;
  align-items: center;
  .btn-export {
    position: absolute;
    right: 0;
  }
}

/* Home Page */
.banner-image-column {
  background-color: #12355b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-up(lg) {    
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  @include media-breakpoint-down(lg) {    
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}
.banner-image {
  @include media-breakpoint-down(lg) {    
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}
.banner-text-card {
  border-radius: 0;
  @include media-breakpoint-up(lg) {    
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  @include media-breakpoint-down(lg) {    
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.gift-icon {
  color: $ed-green;
  border: 2px solid $ed-green;
  border-radius: 50%;
  padding: 10px;
  margin: 0.5rem;
  margin-left: 0;
  height: 4rem;
  &.fa-w-18 {
    width: 4rem;
  }
}
.donation-banner {
  display: flex;
  align-items: center;
  .right-box {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    @include media-breakpoint-down(md) {
      max-width: 80%;
    }
  }
  .text-box {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-end;
    }
    p {
      margin: 5px 0;
    }
    .brand-links {
      display: flex;
    }
  }
}
.donation-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  .lower-box {
    display: flex;
    flex-direction: row;
    @include media-breakpoint-up(md) {
      margin-top: 0.5rem;
    };
    .text-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 0.9rem;
      @include media-breakpoint-down(lg) {
        flex-direction: row;
      }
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
      .share-text {
        max-width: 400px;
        margin-bottom: 0.25rem;
        padding: 0.25rem;
      }
      .brand-links {
        display: flex;
      }
    }
  }
}
.link-and-icon-box {
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  .link-box {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
  }
  .icon-box {
    display: flex;
  }
}
.link-ed-dark-blue {
  color: $ed-dark-blue;
  &:hover {
    color: $ed-dark-blue;
  }
}
.icon {
  height: 1.5rem;
  &.svg-inline--fa {
    width: 1.5rem;
  }
  margin: 0.3rem;
  color: $ed-dark-blue;
  &.inverted {
    color: white;
    background-color: $ed-dark-blue;
    padding: 0.2rem;
    border-radius: 3px;
  }
  &.external-link {
    color: $ed-light-blue;
    height: 1.2rem;
  }
}

/* User Dashboard */
.user-dashboard-card {
  border-radius: $border-radius;
  border-color: $ed-warm-gray-200;
  @include media-breakpoint-up(lg) {
    height: 90%;
  }
  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
  .account-balance-box {
    max-width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .account-balance {
    font-size: 3.4rem;
    .balance-number {
      font-weight: 700;
    }
  }
  .progress {
    height: 10px;
    border-radius: $border-radius;
    background-color: $ed-warm-gray-200;
    margin-bottom: 1rem;
    .progress-bar {
      height: inherit;
      border-radius: $border-radius;
      background-color: $ed-green;
    }
  }
  .recent-activity {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    .recent-transaction {
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(lg) {
        display: flex;
      }
      .transaction-statement {
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        .transaction-summary {
          display: inline;
        }
        .time-since-transaction {
          font-size: 0.8rem;
          margin-left: 0.5rem;
          margin-bottom: 0;
          min-width: fit-content;
          float: right;
        }
      }
    }
  }
  .view-ledger-link {
    text-align: center;
    color: $ed-green;
    font-size: 0.9rem;
  }
}

/* Resources */
.logo-download-box {
  display: flex;
}
.posters-box {
  display: flex;
  flex-wrap: wrap;
}
.thumbnail-box {
  text-align: center;
}
.press-releases-box {
  display: flex;
  justify-content: space-around;
}

/* Transactions */
.styled-table {
  .global-data-table {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    .rdt_TableRow {
      min-height: 26px;
      border-bottom: none;
    }
  }
  .ledger-balance-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border: none;
    font-size: 14px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    .ledger-balance-left-box {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      max-width: 350px;
      .percentage-and-text-box {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        .percentage-box {
          font-size: 2rem;
          font-weight: 900;
          border-radius: $border-radius;
          padding: 0.1rem 0.3rem;
          margin-right: 0.5rem;
          color: white;
          background-color: $ed-warm-gray-200;
          @include media-breakpoint-down(md) {
            font-size: 1.7rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.4rem;
          }
        }
        .text-box {
          font-size: 1rem;
          margin-right: 1rem;
          @include media-breakpoint-down(md) {
            font-size: 0.9rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 0.8rem;
          }
        }
      }
    }
    .ledger-balance-amount {
      font-size: 4rem;
      font-weight: 900;
      color: $ed-green;
      margin-left: 1rem;
      @include media-breakpoint-down(md) {
        font-size: 3rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }
  }
  .progress {
    height: 0.6rem;
    border-radius: $border-radius;
    background-color: $ed-warm-gray-200;
    margin-bottom: 1rem;
    .progress-bar {
      height: inherit;
      border-radius: $border-radius;
      background-color: $ed-green;
    }
  }
  .ledger-footer-link {
    background-color: white;
    border-radius: $border-radius;
    position: relative;
    top: -8px;
    padding-bottom: 1rem;
    padding-right: 1rem;
    text-align: right;
  }
}
.transaction-type-cell {
  width: 100%;
  .transaction-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.secondary {
      color: $ed-muted-warm-gray;
    }
  }
}
.form-table .rdt_Table {
  border: 1px solid $ed-warm-gray-200;
  .rdt_TableRow {
    cursor: default;
    .fa-minus-circle {
      cursor: pointer;
    }
    input {
      height: 80%;
    }
  }
}
.student-table-footer {
  display: flex;
  align-items: center;
  background-color: $ed-secondary-warm-gray;
  color: white;
  height: 36px;
  .label {
    padding: 0 1rem;
    min-width: 150px;
    width: 30%;
  }
  .total {
    padding: 0 1.25rem;
    min-width: 140px;
    width: 20%;
  }
}
.flex-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.student-transaction-link {
  font-size: 0.8rem;
  float: right;
  &:hover {
    cursor: pointer;
  }
  .fa-question-circle {
    margin-left: 0.5rem;
  }
}
.inline-input-row {
  justify-content: space-between;
  .form-control {
    max-width: 100px;
    height: 1.6rem;
  }
}
.breakdown-totals-row {
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
}
.delete-button-light {
  &:hover {
    color: white;
  }
}

/* Tooltips */
.bs-tooltip-top {
  .tooltip-inner {
    color: $ed-warm-gray;
    border: 1px solid $ed-warm-gray-200;
    background-color: white;
    filter: drop-shadow(0 3px 3px #00000066);
    max-width: 350px;
  }
  .arrow::before {
    border-top-color: $ed-warm-gray;
  }
}
.bs-tooltip-right {
  .tooltip-inner {
    color: $ed-warm-gray;
    border: 1px solid $ed-warm-gray-200;
    background-color: white;
    filter: drop-shadow(0 3px 3px #00000066);
    max-width: 350px;
  }
  .arrow::before {
    border-right-color: $ed-warm-gray;
  }
}

// Student stories
.student-story-block p {
  padding: 0 0.5rem 0.5rem 0.5rem;
}
.student-story-block a {
  color: $ed-dark-blue;
}
.student-story-block a.btn-info:hover {
  color: #FFF!important;
}
.alert-student-story {
  padding: 0.5rem 0.25rem;
  background-color: $ed-dark-blue;
  color: #FFF;
}
.story-stacked-icons {
  vertical-align: top;
  height: 0.8em;
  width: 1.2em;
}
.story-stacked-icons .fa-stack-1x {
  color: #FFF;
}
.story-icon-size {
  font-size: 1.2rem;
}
.impact-stories.student-story-block {
  background-color: $ed-off-white;
}

// Reports
.customize-report-link {
  font-size: 0.8rem;
  float: right;
  &:hover {
    cursor: pointer;
  }
}

// Date range selector
.react-daterange-picker__wrapper {
  border: thin solid #ccc!important;
  border-radius: 6px;
}
.react-daterange-picker__range-divider {
  padding-right: 5px;
  padding-left: 5px;
}
.react-daterange-picker__inputGroup {
  min-width: auto;
}

/* Report Progress Bar */
.report-progress {
  position: relative;
}

.report-progress-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.report-progress-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-progress-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background-color: #fff;
  color: $ed-warm-gray-200;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;

  .report-progress-list-item--complete & {
    color: #fff;
    background-color: $ed-green;
  }

  .report-progress-list-item--active & {
    color: #fff;
    background-color: $ed-light-blue;
  }
}

.report-progress-bar {
  position: absolute;
  top: 1.8rem;
  left: 3rem;
  display: flex;
  background-color: #fff;
  height: 0.575rem;
  width: calc(100% - 6rem);
  transform: translateY(-50%);
  z-index: -1;
}

.report-progress-bar-section {
  display: block;
  height: 0.575rem;

  &--complete {
    background-color: $ed-green;
  }

  &--active {
    background-color: $ed-light-blue;
  }
}

.report-progress-title {
  width: 6rem;
  line-height: 1.25;
  text-align: center;

  .report-progress-list-item--complete & {
    color: $ed-green;
    font-weight: 600;
  }

  .report-progress-list-item--active & {
    color: $ed-light-blue;
    font-weight: 600;
  }
}

.report-progress-icon {
  font-size: 1.75rem;
}
